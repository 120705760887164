import { type BaseData, copyToClipboard, getWxUrl, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';

export class ShareSectionModel {
  @observable public visible = false;
  @observable public ref: FormInstance;
  @observable public parent = null;
  @observable public ids: any[] = [];

  @action public onShow = (ids) => {
    this.visible = true;
    this.ids = ids;
  };

  @action public setRef = (ref) => {
    this.ref = ref;
  };
  @action public onCancel = () => {
    this.visible = false;
    this.ref.resetFields();
  };

  @action public onSubmit = async () => {
    const isValidate = await this.ref.validateFields();
    if (!isValidate) {
      return;
    }
    const values = this.ref.getFieldsValue();
    const postParams = {
      ...values,
      opusIdList: this.ids,
    };

    try {
      const createId = await request<BaseData<any>>({
        url: '/pbb/platform/sys/create/opus/group',
        method: 'post',
        data: {
          ...postParams,
        },
      });

      if (!createId.data) {
        throw new Error('创建失败');
      }
      getWxUrl({
        path: '/detailsPackage/anchorSquare/index',
        query: `groupId=${createId.data}`,
        environment: 1,
        channel: 5,
      }).then((url) => {
        if (!url) {
          return message.error('复制分享链接失败');
        }
        copyToClipboard(url);
        return message.success('复制分享链接成功');
      });

      // 可以在这里添加更多的逻辑，比如复制分享链接到剪贴板
    } catch (err) {
      console.log(err);
    } finally {
      this.onCancel();
    }
  };
}
