import { Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react';
import type { ShareSectionModel } from './shareSectionStore';

const ShareSection = ({ store }: { store: ShareSectionModel }) => {
  return (
    <Modal
      title="批量分享名称"
      // centered={true}
      open={store.visible}
      onCancel={store.onCancel}
      onOk={store.onSubmit}
    >
      <Form ref={store.setRef}>
        <Form.Item
          label="名称"
          name="name"
        >
          <Input.TextArea
            maxLength={50}
            showCount={true}
            autoSize={{ minRows: 4 }}
            className="flex-1 ml-2"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(ShareSection);
